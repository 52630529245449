import React from 'react';
import Design from './Design';
import styled from 'styled-components';
import seedrandom from 'seedrandom';
import Cookies from 'js-cookie';

/* ===================================== */
/* STYLE UTILS */
/* ===================================== */

const shadows = `
    box-shadow: 0px -4px 30px rgba(60,20,20,0.1);
    z-index: 10;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // cool shadow from direct warm source
        box-shadow: 0px 4px 12px rgba(40,50,100,0.1);
        z-index: -10;
    }
`;

/* ===================================== */
/* STYLES */
/* ===================================== */

const dWidth = 640;
const dHeight = 480;
const dMarginX = 20;
const dMarginY = 80;

const CreateButton = styled.div`
    position: absolute;
    left: 50%;
    width: 16rem;
    margin-left: -8rem;
    text-align: center;

    padding: 0.75rem 0.75rem;
    border-radius: 3px;
    font-weight: 600;

    /*color: #2255ff;*/
    color: #555;
    border: 1px solid rgba(100,100,100,0.3);

    user-select: none;

    cursor: pointer;

    &:hover {
        border-color: #555;
        background-color: #fff;
    }

    &:active {
        border-color: pink;
        color: pink;
        background-color: #fff;
    }

    transition-duration: 0.0;

    font-size: 16px;

    top: 1.5rem;

    @media only screen and (max-width: 550px) {
        width: auto;
        margin-left: 0;
        margin-right: 0;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        top: auto;
    }
`;

const Wrapper = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faf9f8;
`;

const ContainerScroller = styled.div`
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;

    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    @media only screen and (orientation: landscape) {
        overflow-x: hidden;
        -webkit-overflow-scrolling: none;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const ContainerWrapper = styled.div`
    width: ${dWidth+2*dMarginX}px;
    height: 100%;
    padding: ${dMarginY}px ${dMarginX}px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    position: relative;
    width: ${dWidth}px;
    height: ${dHeight}px;

    @media only screen and (orientation: portrait) and (max-width: ${0.75*(dWidth+2*dMarginX)}px) {
        transform: scale(0.78) translateY(-10px);
    }

    @media only screen and (orientation: portrait) and (max-width: ${0.50*(dWidth+2*dMarginX)}px) {
        transform: scale(0.73) translateY(-10px);
    }

    @media only screen and (orientation: landscape) and (max-height:${0.75*(dHeight+2*dMarginY)}px) {
        transform: scale(0.53) translateY(50px);
    }

    @media only screen and (orientation: landscape) and (max-height:${0.50*(dHeight+2*dMarginY)}px) {
        transform: scale(0.43) translateY(50px);
    }

    overflow: hidden;

    background-color: white;
    // warm shadow from reflected cool source?
    box-shadow: 0px -4px 30px rgba(60,20,20,0.1);
    &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // cool shadow from direct warm source
        box-shadow: 0px 4px 12px rgba(40,50,100,0.1);
    }
`;

const InfoModal = styled.div`
    z-index: 200;
`;

const InfoModalOverlay = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
    opacity: ${props => props.visible?'1.0':'0.0'};
    pointer-events: ${props => props.visible?'auto':'none'};
`;

const InfoModalContent = styled.div`
    position: absolute;

    left: ${props => props.visible?'30%':'110%'};
    width: 70%;
    padding: 10%;

    @media only screen and (max-width: 430px) {
        left: ${props => props.visible?'15%':'110%'};
        width: 85%;
        padding: 8%;
    }

    top: 0;
    bottom: 0;

    background-color: #faf9f8;

    p, ol {
        line-height: 1.45;
        max-width: 50em;
    }

    li {
        margin-bottom: 0.5rem;
        line-height: 1.45;
    }

    /* has to be scroll, not auto */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
`;

const InfoModalTitle = styled.div`
    font-size: 26px;
    font-weight: 700;
`;

const InfoModalSubTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    padding-top: 0.75em;
`;

const CookieConsent = styled.div`
    display: ${props => props.visible?'block':'none'};

    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 15em;
    padding: 1.5rem 2.0rem;
    text-align: center;
    background-color: rgba(0,0,0,0.7);
    color: white;
    ${shadows}

    line-height: 1.4;

    z-index: 100;

    @media only screen and (max-width: 430px) {
        left: 1.5rem;
        width: auto;
    }
`;

const CookieLink = styled.span`
    color: #99bbff;
    text-decoration: none;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
        opacity: 1.0;
    }

    &:active {
        color: pink;
    }

    text-decoration: underline;
`;

const InfoLink = styled.div`
    text-decoration: none;
    cursor: pointer;
    color: #777;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;

    transition-duration: 0.0;

    border-radius: 3px;

    &:hover {
        color: #333;
        background-color: white;
    }

    &:active {
        color: pink;
    }

    padding: 1.0rem 1.5rem;
    user-select: none;
    text-align: center;
    float: 1;

    @media only screen and (max-width: 500px) {
        width: 50%;
    }
`;

const LinkContainer = styled.div`
    position: absolute;
    left: 1.0rem;
    right: 1.0rem;
    top: 1.0rem;

    display: float;
    justify-content: space-between;

`;

/* ===================================== */
/* COMPONENT */
/* ===================================== */

class App extends React.Component {

    constructor(props) {
        super(props);
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleAboutClick = this.handleAboutClick.bind(this);
        this.handleCookieCloseClick = this.handleCookieCloseClick.bind(this);
        this.handleCookieInfoClick = this.handleCookieInfoClick.bind(this);
        this.handlePrivacyPolicyClick = this.handlePrivacyPolicyClick.bind(this);

        this.handleAboutClose = this.handleAboutClose.bind(this);
        this.handlePrivacyPolicyClose = this.handlePrivacyPolicyClose.bind(this);

        this.aboutModalContent = React.createRef();
        this.privacyModalContent = React.createRef();

        this.state = {
            seed: (new seedrandom()).int32(),
            cookieConsentVisible: !Cookies.get('seen_cookie_message'),
            privacyPolicyVisible: false,
            aboutVisible: false,
        }
    }

    handleCreateClick() {
        this.setState({ seed: (new seedrandom()).int32() });
    }

    handleAboutClick(e) {
        this.aboutModalContent.current.scrollTop = 0;
        this.setState({ aboutVisible: true });
    }

    handleAboutClose() {
        this.setState({ aboutVisible: false });
    }

    handlePrivacyPolicyClick(e) {
        this.privacyModalContent.current.scrollTop = 0;
        this.setState({ privacyPolicyVisible: true });
    }

    handlePrivacyPolicyClose() {
        this.setState({ privacyPolicyVisible: false });
    }

    addCookie() {
        Cookies.set('seen_cookie_message', 'true', { expires: 30 });
    }

    handleCookieInfoClick() {
        this.setState({
            privacyPolicyVisible: true,
            cookieConsentVisible: false
        });
        this.addCookie();
    }

    handleCookieCloseClick() {
        this.setState({ cookieConsentVisible: false });
        this.addCookie();
    }

    render() {
        return (
            <Wrapper>
                <InfoModal visible={this.state.aboutVisible}>
                    <InfoModalOverlay visible={this.state.aboutVisible} onClick={this.handleAboutClose} />
                    <InfoModalContent ref={this.aboutModalContent} visible={this.state.aboutVisible}>
                        <InfoModalTitle>About Uibot</InfoModalTitle>

                        <p>What if we could replace the process of designing UIs with, well, bots? What if you could have an infinite amount of UI designs to choose from &mdash; would that lead to new directions that you wouldn't otherwise explore?</p>

                        <p>Uibot is an experiment on how far one could automate the generation of visual designs, what kinds of advantages it would lead to and what issues one would face.</p>

                        <InfoModalSubTitle>Inspiration and implementation</InfoModalSubTitle>

                        <p>Uibot is inspired by <a href="https://jon.gold/2016/06/declarative-design-tools/">declarative design tools</a> by <a href="https://twitter.com/jongold">Jon Gold</a> from a few years back and <a href="https://programmingdesignsystems.com">Programming Design Systems</a> by <a href="https://twitter.com/runemadsen">Rune Madsen</a>. The <a href="https://twitter.com/proc_gen">Twitter feed for the procedural-generation blog</a> is quite inspiring as well.</p>

                        <p>Inspiration for the style of the designs came from <a href="https://tailwindcss.com">Tailwind CSS</a>, a <a href="https://codepen.io/adamwathan/full/RxWrZr/">Coinbase Clone</a> made using Tailwind by <a href="https://twitter.com/adamwathan">Adam Wathan</a>. The analytical approach in <a href="https://refactoringui.com/book/">Refactoring UI book</a> was an inspiration, too. And I browsed through some dashboard designs on <a href="http://dribbble.com">Dribbble</a> as well.</p>

                        <p>The implementation uses a <a href="https://gist.github.com/tkon99/4c98af713acc73bed74c">name generator</a> by Thomas Konings for generating company names and the <a href="https://github.com/Qix-/color#readme">color</a> library for color manipulation. Profile pictures have been selected from <a href="https://pravatar.cc/">Pravatar.cc</a> by Simon Asika. Uibot is built using React and <a href="https://www.styled-components.com">Styled Components</a>.</p>

                        <InfoModalSubTitle>Next steps</InfoModalSubTitle>

                        <p>Some different ways to take this further came into my mind, although I’m not sure if I will have the time to work on them:</p>

                        <ol>
                            <li>One would be a ’genetic’ UI, where the user could mix designs easily.</li>
                            <li>Another would be to have a chat interface for customizing a UI.</li>
                            <li>And a third would be to use the generated layouts as training material for AI:s — so that the AI would learn to distinguish key features of designs, such as accent colors and layout types.</li>
                            <li>And perhaps also a hot-or-not UI, where the visitors could rate the designs easily, could be interesting.</li>
                        </ol>

                        <InfoModalSubTitle>Feedback</InfoModalSubTitle>

                        <p>Any comments, suggestions or feedback? You can <a href="https://twitter.com/jaukia">find me on Twitter.</a></p>
                    </InfoModalContent>

                </InfoModal>

                <InfoModal visible={this.state.privacyPolicyVisible}>
                    <InfoModalOverlay visible={this.state.privacyPolicyVisible} onClick={this.handlePrivacyPolicyClose} />
                    <InfoModalContent ref={this.privacyModalContent} visible={this.state.privacyPolicyVisible}>
                        <InfoModalTitle>Privacy Policy</InfoModalTitle>

                        <p>This Privacy Policy document contains types of information that is collected and recorded by Uibot and how it is used.</p>

                        <InfoModalSubTitle>Cookies</InfoModalSubTitle>

                        <p>Like almost any other website, Uibot uses cookies, to store information about the visitors. When visiting Uibot, cookies are stored for Google Analytics use and for the first visit cookie notification.</p>

                        <p>Google Analytics stores information about the pages you visit, how long you spend on each page, how you got to the site and what you click on while you’re visiting the site. We do not collect or store your personal information (for example your name or address). For Google Analytics, IP anonymization is turned on and ad features off. You can <a href="https://tools.google.com/dlpage/gaoptout">opt out of Google Analytics cookies here</a>.</p>

                        <p>When visiting Uibot, you see a pop-up message that notifies you about cookie use. We store a cookie so that your computer knows you’ve seen the popup and knows not to show it again.</p>

                        <InfoModalSubTitle>External services</InfoModalSubTitle>

                        <p>Two external services are used by Uibot: <a href="https://fonts.google.com/">Google Fonts</a> and <a href="https://analytics.google.com/analytics/web/">Google Analytics</a>. Data for these services may be transferred outside the EEC (European Economic Area). In the case of Google Fonts, Google acts as a data controller for the any personal data that is processed.</p>

                        <InfoModalSubTitle>Contact information</InfoModalSubTitle>

                        <p>By using our website, you hereby consent to our Privacy Policy. The administrator of this site is Simplicity Design Oy, FI27190676. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us via email at janne@simplicitydesign.fi.</p>

                    </InfoModalContent>
                </InfoModal>

                <ContainerScroller>
                    <ContainerWrapper>
                        <Container>
                            <Design seed={this.state.seed} />
                        </Container>
                    </ContainerWrapper>
                </ContainerScroller>
                <CookieConsent visible={this.state.cookieConsentVisible}>
                    Uibot uses cookies to load assets and track analytics. <CookieLink onClick={this.handleCookieInfoClick}>Find out more about cookies</CookieLink> or <CookieLink onClick={this.handleCookieCloseClick}>Hide this message</CookieLink>
                </CookieConsent>
                <LinkContainer>
                    <InfoLink onClick={this.handleAboutClick}>About Uibot</InfoLink>
                    <InfoLink onClick={this.handlePrivacyPolicyClick}>Privacy</InfoLink>
                </LinkContainer>
                <CreateButton onClick={this.handleCreateClick}>Style again!</CreateButton>
            </Wrapper>
        );
    }
};

export default App;
