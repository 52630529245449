import { getRandomFromArray } from './util';

function makeSizing(rng) {
    
    const s1 = {
        xxxs: 2,
        xxs: 4,
        xs: 8,
        s: 8,
        m: 16,
        l: 24,
        xl: 36,
        xxl: 48,
        xxxl: 60
    };

    const s2 = {
        xxxs: 2,
        xxs: 3,
        xs: 6,
        s: 6,
        m: 12,
        l: 18,
        xl: 30,
        xxl: 42,
        xxxl: 56
    }

    const s3 = {
        xxxs: 2,
        xxs: 4,
        xs: 8,
        s: 10,
        m: 12,
        l: 16,
        xl: 32,
        xxl: 40,
        xxxl: 60
    }
    
    const s4 = {
        xxxs: 2,
        xxs: 4,
        xs: 8,
        s: 14,
        m: 16,
        l: 18,
        xl: 32,
        xxl: 40,
        xxxl: 60
    }

    return getRandomFromArray([s1, s2, s3, s4], rng);
}

export default makeSizing;