
function getRandomFloat(min, max, rng) {
    return rng() * (max - min) + min;
}

function getRandomInt(min, max, rng) {
  	return Math.floor(rng() * (max - min)) + min;
}

function getRandomFromArray(arrayIn, rng) {
    return arrayIn[Math.floor(rng()*arrayIn.length)];
}

function getFlip(rng, weight=0.5) {
    return rng()>(1.0-weight);
}

export { getRandomInt, getRandomFloat, getRandomFromArray, getFlip }
