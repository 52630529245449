import { getFlip, getRandomFromArray } from './util';

function makeEdgeDecoration(rng) {
    const edgeDecoration = {};

    let cardShadowSpread = getRandomFromArray([2, 4, 6, 8, 14, 20, 30], rng);
    let cardShadowDistance = getRandomFromArray([1, 2, 3], rng);
    let cardShadowOpacity = (Math.log(cardShadowSpread+2.0)/32.0)*getRandomFromArray([0.8,0.8,1.2], rng);
    let cardShadowColor = getRandomFromArray(['0,20,40','0,30,60','0,64,128'], rng);

    edgeDecoration.card = '0 '+cardShadowDistance+'px '+cardShadowSpread+'px 0 rgba('+cardShadowColor+','+cardShadowOpacity+')';
    edgeDecoration.cardShadowSpread = cardShadowSpread;

    edgeDecoration.accentBorderWidth = getFlip(rng,0.2)?4:0;
    edgeDecoration.borderWidth = getFlip(rng,0.3)?1:0;
    edgeDecoration.borderRadius = getRandomFromArray([0,1,2,3,5],rng);
    edgeDecoration.narrowCardTitleBorder = getFlip(rng,0.5);

    edgeDecoration.decorativeBorderWidth = getFlip(rng,0.5)?1:0;

    edgeDecoration.selectedLinkBorderWidthTop = getRandomFromArray([0,1,2],rng);
    edgeDecoration.selectedLinkBorderWidthLeft = getRandomFromArray([0,2],rng);

    edgeDecoration.cardPadding = getFlip(rng);

    if(!edgeDecoration.cardPadding) {
        edgeDecoration.borderWidth=0;
        edgeDecoration.borderRadius=0;
        edgeDecoration.card = 'none';
        edgeDecoration.cardShadowSpread = 0;
    }

    edgeDecoration.buttonBorderWidth = getRandomFromArray([0,1,2],rng);
    edgeDecoration.buttonBorderRadius = getRandomFromArray([0,1,2,3,100],rng);

    edgeDecoration.profileRadius = (edgeDecoration.buttonBorderRadius>1&&getFlip(rng,0.6))?edgeDecoration.buttonBorderRadius:100;

    return edgeDecoration;
}


export default makeEdgeDecoration;
