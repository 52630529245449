import seedrandom from 'seedrandom';
import { getFlip, getRandomInt } from './util';

import makeType from './type';
import makeSizing from './sizing';
import makePalette from './palette';
import makeCompanyName from './companyName';
import makeTextSizing from './textSizing';
import makeEdgeDecoration from './edgeDecoration';

function isEmpty(color) {
    return (color==='transparent' || color==='#ffffff' || color==='#FFFFFF');
}

// make the rejection rules as specific as possible
function rejectCardFrame(config) {
    if(config.palette.frame.isDark) {
        return (config.palette.frame.bg === config.palette.card.bg) && (config.edgeDecoration.cardPadding) && (config.edgeDecoration.borderWidth === 0);
    } else {
        return isEmpty(config.palette.frame.bg) && config.edgeDecoration.cardShadowSpread <10;
    }
}

function designCriticAccepted(config) {
    if(rejectCardFrame(config)) {
        return false;
    } else {
        return true;
    }

}

function generateConfig(rng) {
    let config = {};

    config.type = makeType(rng);
    config.sizing = makeSizing(rng);
    config.palette = makePalette(/*config.borders.borderWidth, */rng);
    config.companyName = makeCompanyName(rng);
    config.textSizing = makeTextSizing(rng);
    config.edgeDecoration = makeEdgeDecoration(rng);
    config.verticalNav = getFlip(rng);
    config.profileImage = getRandomInt(1, 29, rng);
    return config;
}

function makeConfig(seed) {
    let rng = new seedrandom(seed);

    while(true) {
        let config = generateConfig(rng);
        if(designCriticAccepted(config)) {
            return config;
        }
    }

}

export default makeConfig;
