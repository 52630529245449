

function makeTextSizing(rng) {

    const textSizing = {
        s: 12,
        m: 14,
        l: 18,
        xl: 24,
        xxl: 36
    }

    return textSizing;
}

export default makeTextSizing;
