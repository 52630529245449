import { getRandomFromArray, getRandomFloat, getFlip } from './util';
import Color from 'color';

const MID_GRAY_HUES = [
    '#8795a1',
    '#87959B',
    '#919191',
    '#888DA8'
];

const NEUTRAL_ACCENT_COLORS = [
    '#3895FB',
    '#396BeF',
    '#2779bd',
    '#455CC7',
    '#3FC8C6',
    '#6B75C9',
];

const INTENSE_ACCENT_COLORS = [
    '#0349DB',
    '#EB7234',
    '#B33334',
    '#006BEB',
    '#0284FE']
;

function makePalette(rng) {

    // ----------------------
    // CREATE HUES
    // ----------------------

    //window.Color = Color;
    //let lightBackground = getRandomFromArray(['#f1f5f8', '#F4F7FC', '#F4F4F7', '#F7F8FC', '#F4F7FC', '#F0F3F8', '#F5F8FA'], rng);

    let hue550 = getRandomFromArray(MID_GRAY_HUES, rng);
    let white = '#ffffff';

    let hues = {
        0:   white,
        100: Color(hue550).lighten(0.68).saturate(1.0).hex(),
        200: Color(hue550).lighten(0.64).saturate(1.0).hex(),
        300: Color(hue550).lighten(0.57).saturate(0.6).hex(),
        400: Color(hue550).lighten(0.32).saturate(0.4).hex(),
        500: Color(hue550).lighten(0.10).saturate(0.2).hex(),
        600: Color(hue550).darken(0.24).saturate(0.4).hex(),
        700: Color(hue550).darken(0.51).saturate(0.6).hex(),
        800: Color(hue550).darken(0.63).saturate(0.8).hex(),
        900: Color(hue550).darken(0.75).saturate(1.0).hex(),
    };

    let getHue = (value, isDark) => {
        return isDark?hues[900-value]:hues[value];
    };

    // ----------------------
    // CREATE ACCENT
    // ----------------------

    let lightAccent;

    let neutralAccent = getFlip(rng);
    let monochromeAccent = !neutralAccent && getFlip(rng,0.2);

    if(neutralAccent) {
        lightAccent = Color(getRandomFromArray(NEUTRAL_ACCENT_COLORS, rng))
            .rotate(getRandomFloat(-20.0,20.0,rng)).hex();
    } else if(monochromeAccent) {
        lightAccent = hues.hue800;
    } else {
        lightAccent = Color(getRandomFromArray(INTENSE_ACCENT_COLORS, rng))
            .rotate(getRandomFloat(-45.0,45.0,rng)).hex();
    }

    let accentObj = Color(lightAccent);
    let accentLightness = accentObj.lightness();
    let darkAccent = accentObj.lightness(100.0-accentLightness).hex();

    // ----------------------
    // COMPOSE STARTING LIGHT PALETTE FOR ELEMENTS
    // ----------------------

    let createPalette = (isDark, accent) => {

        let normal =      {bg: 'transparent', fg: getHue(800,isDark)};
        let light =       {bg: 'transparent', fg: getHue(500,isDark)};
        let lighter =     {bg: 'transparent', fg: getHue(400,isDark)};
        let normalSolid = {bg: getHue(0,isDark), fg: getHue(800,isDark)};
        let borderColor = getHue(isDark?200:300, isDark);

        let palette = {
            navi:         {...normal},
            headerLeft:   {...normal, accent: accent},
            headerTop:    {...light, accent: accent},
            frame:        {...normalSolid, accent: accent},
            card:         {...normalSolid, accent: accent},
            cardLight:    {...lighter},
            footer:       {...light},
            border:       {fg: borderColor},
            button:       {fg: white, bg: accent}
        };

        if(getFlip(rng)) {
            // add a background color
            palette.frame.bg = getHue(100,isDark);

            if(getFlip(rng, 0.5)) {
                // white bg for top section
                palette.navi.bg = getHue(0,isDark);
                palette.headerLeft.bg = getHue(0,isDark);
                palette.headerTop.bg = getHue(0,isDark);
            }

            if(getFlip(rng)) {
                // white bg for footer
                palette.footer.bg = getHue(0,isDark);
            }
        }

        if(monochromeAccent) {
            palette.button.fg = getHue(0,isDark);
            palette.button.bg = getHue(800,isDark);
        }

        return palette;
    };

    let lightPalette = createPalette(false, lightAccent);
    let darkPalette = createPalette(true, darkAccent);

    // ----------------------
    // DARK MODE FLIPS
    // ----------------------

    let palette = lightPalette;

    // use dark mode
    if(getFlip(rng,0.2)) {
        // equal probabilities for all dark modes
        let darkMode = getRandomFromArray(['navi','topheader','all'],rng);

        // set dark mode for content, cards and footers
        if(darkMode === 'all') {
            palette = darkPalette;
        } else {
            // set dark mode for navi & headerLeft
            palette.navi = darkPalette.navi;
            if(palette.navi.bg === 'transparent') palette.navi.bg = darkPalette.frame.bg;

            palette.headerLeft = darkPalette.headerLeft;
            if(palette.headerLeft.bg === 'transparent') palette.headerLeft.bg = darkPalette.frame.bg;

            // set dark mode for topheader
            if(darkMode === 'topheader' || darkMode === 'all') {
                palette.headerTop = darkPalette.headerTop;
                if(palette.headerTop.bg === 'transparent') palette.headerTop.bg = darkPalette.frame.bg;
            }
        }

        if(!monochromeAccent && getFlip(rng,0.5)) {
            palette.headerLeft.bg = palette.headerLeft.accent;
            palette.headerLeft.accent = white;
        }

        if(neutralAccent && getFlip(rng,0.5)) {
            palette.navi.fg = white;
            palette.navi.bg = lightAccent;
        }

    }

    return palette;
}

export default makePalette;
