import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { MdDashboard, MdCompareArrows, MdAccountBalanceWallet, MdWork, MdSettings } from 'react-icons/md';
import { GiStarFormation } from 'react-icons/gi';

import makeConfig from './make/config';

/* ===================================== */
/* STYLE UTILS */
/* ===================================== */

const pin = `
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const type = (fontSize, fontWeight, useCaps) => `
    text-transform: ${useCaps?'uppercase':'none'};
    font-size: ${useCaps?Math.round(0.93*fontSize)+'px':fontSize+'px'};
    font-weight: ${fontWeight};
`;

const collapseLeftPadding = (p) => {
    return (p.theme.verticalNav) &&
           (p.theme.palette.headerLeft.bg===p.theme.palette.frame.bg || p.theme.palette.headerLeft.bg==='transparent') &&
           (p.theme.edgeDecoration.borderWidth === 0);
};

const collapseTopPadding = (p) => {
    return (!p.theme.verticalNav) &&
           (p.theme.palette.headerTop.bg===p.theme.palette.frame.bg || p.theme.palette.headerTop.bg==='transparent') &&
           (p.theme.edgeDecoration.borderWidth === 0);
};

const collapseBottomPadding = (p) => {
    return (!p.theme.verticalNav) &&
           (p.theme.palette.footer.bg===p.theme.palette.frame.bg || p.theme.palette.footer.bg==='transparent') &&
           (p.theme.edgeDecoration.borderWidth === 0);
};

/* ===================================== */
/* MAIN STRUCTURE STYLES */
/* ===================================== */

const Frame = styled.div`
    font-family: ${p => p.theme.type.fontFamily};
    background-color: ${p => p.theme.palette.frame.bg};

    position: absolute;
    ${pin}
    display: flex;
    flex-direction: row;

    user-select: none;
    cursor: default;
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

/* ===================================== */
/* NAVI STYLES */
/* ===================================== */

const Navi = styled.div`
    height: ${p => p.theme.verticalNav?0:p.theme.sizing.xxxl}px;
    opacity: ${p => p.theme.verticalNav?0.0:1.0};
    background-color: ${p => p.theme.palette.navi.bg};
    padding: 0 ${p => p.theme.sizing.m}px 0 ${p => p.theme.sizing.m}px;
    border-top: ${p => p.theme.verticalNav?0:p.theme.edgeDecoration.accentBorderWidth}px solid ${p => p.theme.palette.frame.accent};

    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const NaviLink = styled.div`
    ${p => type(p.theme.textSizing.m, p.theme.type.textWeight.navi, p.theme.type.caps.navi)};
    padding: 0 ${p => p.theme.sizing.l}px 0 ${p => p.theme.sizing.l}px;
    color: ${p => p.theme.palette.navi.fg};
`;

const Title = styled.span`
    ${p => type(p.theme.textSizing.l, p.theme.type.textWeight.title, p.theme.type.caps.title)};
    color: ${p => p.theme.palette.navi.fg};

    flex-grow: 1;
`;

/* ===================================== */
/* PROFILE STYLES */
/* ===================================== */

const Profile = styled.div`
    width: ${p => p.theme.sizing.xl}px;
    height: ${p => p.theme.sizing.xl}px;
    border-radius: ${p => p.theme.edgeDecoration.profileRadius}px;
    background: rgba(255, 255, 255, 0.2) url('/profiles/${p => p.theme.profileImage}.jpeg') top left no-repeat;
    background-size: cover;
`;

const ProfileSection = styled.div`
    bottom: ${p => p.theme.sizing.m}px;

    position: absolute;
`;

/* ===================================== */
/* HEADER STYLES */
/* ===================================== */

const HeaderLeft = styled.div`
    width: ${p => p.theme.verticalNav?p.theme.sizing.xxxl:0}px;
    opacity: ${p => p.theme.verticalNav?1.0:0.0};

    flex-shrink: 0;

    background-color: ${p => p.theme.palette.headerLeft.bg};
    padding-top: ${p => p.theme.sizing.s}px;
    border-right: ${p => p.theme.verticalNav?(!p.theme.edgeDecoration.cardPadding?1:p.theme.edgeDecoration.borderWidth):0}px solid ${p => p.theme.palette.border.fg};

    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;

    transition-delay: 0.1s;
`;

const HeaderLeftLink = styled.div`

    margin-top: ${p => p.theme.sizing.xs}px;
    margin-bottom: ${p => p.theme.sizing.xs}px;

    padding-top: ${p => p.theme.sizing.xs}px;
    padding-bottom: ${p => p.theme.sizing.xs}px;

    color: ${p => p.selected?p.theme.palette.headerLeft.accent:p.theme.palette.headerLeft.fg};
    font-size: ${p => p.theme.textSizing.l}px;
    border-right: ${p => p.theme.edgeDecoration.selectedLinkBorderWidthLeft}px solid transparent;
    border-left: ${p => p.theme.edgeDecoration.selectedLinkBorderWidthLeft}px solid ${p => p.selected?p.theme.palette.accent:'transparent'};

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: ${p => p.selected?p.theme.palette.headerLeft.accent:p.theme.palette.headerLeft.fg};
        opacity: ${p => p.selected?1.0:0.6};
        transition-duration: 0.0s;
    }
`;

const HeaderTop = styled.div`
    height: ${p => p.theme.verticalNav?0:p.theme.sizing.xxl}px;
    opacity: ${p => p.theme.verticalNav?0.0:1.0};
    background-color: ${p => p.theme.palette.headerTop.bg};
    border-bottom: ${p => p.theme.verticalNav?0:(!p.theme.edgeDecoration.cardPadding?1:p.theme.edgeDecoration.borderWidth)}px solid ${p => p.theme.palette.border.fg};

    display: flex;

    // cannot have overflow hidden to make border work
    /*overflow: hidden;*/

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const HeaderTopLink = styled.div`
    ${p => type(p.theme.textSizing.m, p.theme.type.textWeight.header, p.theme.type.caps.header)};
    margin-left: ${p => p.theme.sizing.m}px;
    margin-right: ${p => p.theme.sizing.s}px;
    color: ${p => p.selected?p.theme.palette.headerTop.accent:p.theme.palette.headerTop.fg};

    border-top: ${p => p.theme.edgeDecoration.selectedLinkBorderWidthTop}px solid transparent;
    border-bottom: ${p => p.theme.edgeDecoration.selectedLinkBorderWidthTop}px solid ${p => (p.selected && !collapseTopPadding(p))?p.theme.palette.accent:'transparent'};
    margin-bottom: ${p => -(!p.theme.edgeDecoration.cardPadding?1:p.theme.edgeDecoration.borderWidth)}px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
        color: ${p => p.selected?p.theme.palette.headerTop.accent:p.theme.palette.headerTop.fg};
        margin-right: ${p => p.theme.sizing.xs}px;

        display: inline-block;
        opacity: 0.85;
        transition-duration: 0.0s;
    }
`;

/* ===================================== */
/* FOOTER STYLES */
/* ===================================== */

const Footer = styled.div`
    height: ${p => p.theme.verticalNav?0:p.theme.sizing.xxl}px;
    opacity: ${p => p.theme.verticalNav?0.0:1.0};
    padding: 0 ${p => p.theme.sizing.m}px 0 ${p => p.theme.sizing.m}px;
    background-color: ${p => p.theme.palette.footer.bg};
    border-top: ${p => p.theme.verticalNav?0:(!p.theme.edgeDecoration.cardPadding?1:p.theme.edgeDecoration.borderWidth)}px solid ${p => p.theme.palette.border.fg};

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
`;

const FooterLeft = styled.div`
    margin-left: -${p => p.theme.sizing.s}px;

    display: flex;
    flex-direction: row;
    flex-grow: 1;
`;

const FooterRight = styled.div`
    margin-right: -${p => p.theme.sizing.s}px;
`;

const FooterLink = styled.div`
    ${p => type(p.theme.textSizing.s, p.theme.type.textWeight.footer, p.theme.type.caps.footer)};
    margin: 0 ${p => p.theme.sizing.s}px 0 ${p => p.theme.sizing.s}px;
    color: ${p => p.theme.palette.footer.fg};
;
`;

/* ===================================== */
/* CARD STYLES */
/* ===================================== */

const CardContainer = styled.div`
    padding-bottom: ${p => !p.theme.edgeDecoration.cardPadding||collapseBottomPadding(p)?0:p.theme.sizing.m}px;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const TopCardWrapper = styled.div`
    padding-top: ${p => !p.theme.edgeDecoration.cardPadding||collapseTopPadding(p)?0:p.theme.sizing.m}px;
    padding-left: ${p => !p.theme.edgeDecoration.cardPadding||collapseLeftPadding(p)?0:p.theme.sizing.m}px;
    padding-right: ${p => !p.theme.edgeDecoration.cardPadding?0:p.theme.sizing.m}px;

    border-bottom: ${p => !p.theme.edgeDecoration.cardPadding?1:0}px solid ${p => p.theme.palette.border.fg};

    display: flex;
`;

const BottomCardWrapper = styled.div`
    padding-top: ${p => !p.theme.edgeDecoration.cardPadding?0:p.theme.sizing.m}px;
    padding-left: ${p => !p.theme.edgeDecoration.cardPadding||collapseLeftPadding(p)?0:p.theme.sizing.m}px;
    padding-right: ${p => !p.theme.edgeDecoration.cardPadding?0:p.theme.sizing.m}px;

    display: flex;
    flex: 1;

    & > *:last-child {
        margin-left: ${p => !p.theme.edgeDecoration.cardPadding?0:p.theme.sizing.m}px;
        border-left: ${p => !p.theme.edgeDecoration.cardPadding?'1px':'auto'} solid ${p => p.theme.palette.border.fg};
    }
`;

const Card = styled.div`
    background-color: ${p => p.theme.palette.card.bg};
    border: ${p => p.theme.edgeDecoration.borderWidth}px solid ${p => p.theme.palette.border.fg};
    border-radius: ${p => p.theme.edgeDecoration.borderRadius}px;
    color: ${p => p.theme.palette.card.fg};
    box-shadow: ${p => p.theme.edgeDecoration.card};
    font-weight: ${p => p.theme.type.textWeight.card};

    display: flex;
    flex-direction: column;
    flex: 1;

    /* HACK, there is a strangle flex bug, where figures are not correctly aligned.
       this might center them at least */
    justify-content: center;
`;

const CardTitle = styled.div`
    padding: ${p => p.theme.sizing.m}px ${p => p.theme.edgeDecoration.narrowCardTitleBorder?0:p.theme.sizing.m}px;
    margin: 0 ${p => p.theme.edgeDecoration.narrowCardTitleBorder?p.theme.sizing.m:0}px;
    border-bottom: ${p => p.theme.edgeDecoration.decorativeBorderWidth}px solid ${p => p.theme.palette.border.fg};

    /* for some reason, faster transition when this is here than in the card color definition */
    color: ${p => p.theme.palette.card.fg};

    flex: 0;
`;

const CardContent = styled.div`
    padding: ${p => p.theme.sizing.m}px;
    flex: 1;
    display: flex;
`;

const CardFigureContent = styled.div`
    padding: ${p => p.theme.sizing.m}px 0;
    text-align: ${p => p.theme.type.figureTextAlign};

    display: flex;
    flex-direction: row;
`;

const CardFigure = styled.div`
    display: flex;
    flex: 1;
    border-right: ${p => p.theme.edgeDecoration.decorativeBorderWidth}px solid ${p => p.theme.palette.border.fg};

    &:last-child {
        border-right: none;
    }

    flex-direction: column;
    padding: 0 ${p => p.theme.sizing.m}px;
`;

const CardFigureValue = styled.div`
    font-size: ${p => p.theme.textSizing.xxl}px;
    font-weight: ${p => p.theme.type.textWeight.figureValue};

    /* for some reason, faster transition when this is here than in the card color definition */
    color: ${p => p.theme.palette.card.fg};

    font-feature-settings: "lnum";
    letter-spacing: -0.3px;
`;

const CardFigureLabel = styled.div`
    ${p => type(p.theme.textSizing.m, p.theme.type.textWeight.figureLabel, p.theme.type.caps.figureLabel)};
    margin-top: ${p => p.theme.sizing.xxxs}px;
    color: ${p => p.theme.palette.cardLight.fg};
`;

const CardFigureValueUnit = styled.span`
    font-size: 70%;
    font-weight: ${p => p.theme.type.textWeight.figureValueUnit};
    margin-left: ${p => p.theme.sizing.xxs}px;

    /* for some reason, faster transition when this is here than in the card color definition */
    color: ${p => p.theme.palette.card.fg};
`;

/* ===================================== */
/* CONTENT PLACEHOLDERS */
/* ===================================== */

const Placeholder = styled.div`
    padding-bottom: ${p => p.theme.sizing.m}px;
    text-align: ${p => p.theme.type.placeholderTextAlign};

    align-self: center;
    display: block;
    width: 100%;

    > svg {
        margin-left: auto;
        margin-right: auto;
        color: ${p => p.theme.palette.cardLight.fg};
        display: ${p => (p.theme.type.placeholderTextAlign==='center' && p.theme.verticalNav)?'block':'none'};
    }
`;

const PlaceholderDescription = styled.div`
    font-size: ${p => p.theme.textSizing.m}px;
    line-height: ${p => p.theme.textSizing.m*1.45}px;
    font-weight: ${p => p.theme.type.textWeight.figureLabel};
    color: ${p => p.theme.palette.cardLight.fg};
    text-align: ${p => p.theme.type.placeholderTextAlign};
    padding: ${p => p.theme.type.placeholderTextAlign==='center'?p => p.theme.sizing.m:p.theme.sizing.xs}px ${p => p.theme.type.placeholderTextAlign==='center'?p => p.theme.sizing.m:0}px;
`;

const PlaceholderButton = styled.div`
    ${p => type(p.theme.textSizing.m, p.theme.type.textWeight.button, p.theme.type.caps.button)};
    padding: ${p => p.theme.sizing.m}px ${p => p.theme.sizing.xl}px;
    background-color: ${p => (p.theme.edgeDecoration.buttonBorderWidth>0)?'transparent':p.theme.palette.button.bg};
    color: ${p => (p.theme.edgeDecoration.buttonBorderWidth>0)?p.theme.palette.button.bg:p.theme.palette.button.fg};
    border: ${p => p.theme.edgeDecoration.buttonBorderWidth}px solid ${p => p.theme.palette.button.bg};
    border-radius: ${p => p.theme.edgeDecoration.buttonBorderRadius}px;

    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 16em;
    display: ${p => p.theme.type.placeholderTextAlign==='center'?'inline-block':'block'};

`;

/* ===================================== */
/* COMPONENT */
/* ===================================== */

class Design extends React.Component {

    render() {

        // convert this to a plain function, think about how to pass in the company name

        let config = makeConfig(this.props.seed);
        console.log('config', config);

        return <ThemeProvider theme={config}>
            <Frame>
                <HeaderLeft>
                    <HeaderLeftLink selected={true}><MdDashboard /></HeaderLeftLink>
                    <HeaderLeftLink><MdCompareArrows /></HeaderLeftLink>
                    <HeaderLeftLink><MdAccountBalanceWallet /></HeaderLeftLink>
                    <HeaderLeftLink><MdWork /></HeaderLeftLink>
                    <HeaderLeftLink><MdSettings /></HeaderLeftLink>
                    <ProfileSection>
                        <Profile></Profile>
                    </ProfileSection>
                </HeaderLeft>
                <Right>
                    <Navi>
                        <Title>{config.companyName}
                        </Title>
                        <NaviLink>Help
                        </NaviLink>
                        <Profile>
                        </Profile>
                    </Navi>
                    <HeaderTop>
                        <HeaderTopLink selected={true}><MdDashboard /> Dashboard</HeaderTopLink>
                        <HeaderTopLink><MdCompareArrows /> Connect</HeaderTopLink>
                        <HeaderTopLink><MdAccountBalanceWallet /> Accounts</HeaderTopLink>
                        <HeaderTopLink><MdWork /> Tools</HeaderTopLink>
                        <HeaderTopLink><MdSettings /> Settings</HeaderTopLink>
                    </HeaderTop>
                    <CardContainer>
                        <TopCardWrapper>
                            <Card>
                                <CardFigureContent>
                                    <CardFigure>
                                        <CardFigureValue>
                                            31.2<CardFigureValueUnit>M</CardFigureValueUnit>
                                        </CardFigureValue>
                                        <CardFigureLabel>
                                            Completed
                                        </CardFigureLabel>
                                    </CardFigure>
                                    <CardFigure>
                                        <CardFigureValue>
                                            12.2<CardFigureValueUnit>M</CardFigureValueUnit>
                                        </CardFigureValue>
                                        <CardFigureLabel>
                                            In Progress
                                        </CardFigureLabel>
                                    </CardFigure>
                                    <CardFigure>
                                        <CardFigureValue>
                                            2.3<CardFigureValueUnit>M</CardFigureValueUnit>
                                        </CardFigureValue>
                                        <CardFigureLabel>
                                            Inactive
                                        </CardFigureLabel>
                                    </CardFigure>
                                    <CardFigure>
                                        <CardFigureValue>
                                            97.2<CardFigureValueUnit>%</CardFigureValueUnit>
                                        </CardFigureValue>
                                        <CardFigureLabel>
                                            Activity Rate
                                        </CardFigureLabel>
                                    </CardFigure>
                                </CardFigureContent>
                            </Card>
                        </TopCardWrapper>
                        <BottomCardWrapper>
                            <Card>
                                <CardTitle>Your Favorites</CardTitle>
                                <CardContent>
                                    <Placeholder>
                                        <GiStarFormation size={config.sizing.xxl} />
                                        <PlaceholderDescription>
                                            No Favorites yet. After you've added some favorites, you'll have quick access to them here.
                                        </PlaceholderDescription>
                                    </Placeholder>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardTitle>Active Agents</CardTitle>
                                <CardContent>
                                    <Placeholder>
                                        <PlaceholderButton>
                                            Add an agent
                                        </PlaceholderButton>
                                    </Placeholder>
                                </CardContent>
                            </Card>
                        </BottomCardWrapper>
                    </CardContainer>
                    <Footer>
                        <FooterLeft>
                            <FooterLink>Home</FooterLink>
                            <FooterLink>Careers</FooterLink>
                            <FooterLink>Legal & Privacy</FooterLink>
                        </FooterLeft>
                        <FooterRight>
                            <FooterLink>&copy; 2019 {config.companyName}</FooterLink>
                        </FooterRight>
                    </Footer>
                </Right>
            </Frame>
        </ThemeProvider>;
    }
}

export default Design;
