import WebFont from 'webfontloader';
import { getRandomFromArray, getFlip } from './util';

function makeType(rng) {

    // ----------------------
    // RANDOMIZE FONT
    // ----------------------

    /* Still missing:
       - iA Writer Quattro (similar to IBM Plex though)
       - Inter
       - Public Sans
       - Clear Sans
     */

     /* These I tried, but didn't like:
       - Montserrat
       - Oswald
       - Exo 2
       - PT Sans (ok but boring)
       - Quicksand (only weight 500 looks good, heavy weights have kerning issues)
      */

    let fonts = [
        'system',
        'helvetica',
        'Open Sans',
        'Roboto',
        'Lato',
        'IBM Plex Sans',
        'Overpass',
        'Source Sans Pro',
        'Raleway',
        'Work Sans',
        'Titillium Web',
        'Libre Franklin',
        'Archivo',
        'Fira Sans',
        'Nunito',
        'Nunito Sans',
        'Barlow',
        'Montserrat'
    ]

    let fontFamily = getRandomFromArray(fonts, rng);

    // ----------------------
    // ADJUST FONT WEIGHTS TO MATCH THOSE IN THE FONT
    // ----------------------

    let regularWeight;
    let mediumWeight;
    let heavyWeight;

    if(fontFamily==='Roboto') {
        regularWeight = 400;
        mediumWeight = 500;
        heavyWeight = 700;
    } else if(fontFamily==='Lato') {
        regularWeight = 400;
        mediumWeight = 400;
        heavyWeight = 700;
    } else if(fontFamily==='Open Sans' || fontFamily==='Overpass' || fontFamily==='Source Sans Pro' || fontFamily==='Titillium Web' || fontFamily==='Nunito' || fontFamily==='Nunito Sans') {
        regularWeight = 400;
        mediumWeight = 600;
        heavyWeight = 700;
    } else {
        regularWeight = 400;
        mediumWeight = 500;
        heavyWeight = 600;
    }

    // ----------------------
    // LOAD FONT
    // ----------------------

    if(fontFamily==='system') {
        fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif';
    } else if(fontFamily==='helvetica') {
        fontFamily = '"Helvetica Now", "Helvetica Neue", Helvetica, Arial, sans-serif';
    } else {
        WebFont.load({
            google: {
                families: [fontFamily+':'+regularWeight+((regularWeight!==mediumWeight)?(','+mediumWeight):'')+','+heavyWeight+':latin']
            }
        });
        fontFamily = '"'+fontFamily+'"';
    }

    // ----------------------
    // ADJUST TEXT TRANSFORMS
    // ----------------------

    let caps = {};

    caps.navi = getFlip(rng);
    caps.header = getFlip(rng);
    caps.footer = getFlip(rng);
    caps.title = getFlip(rng);
    caps.figureLabel = getFlip(rng);
    caps.button = getFlip(rng);

    let titleTextWeight = getRandomFromArray([regularWeight,mediumWeight,heavyWeight], rng);
    let headerWeight = getRandomFromArray([regularWeight,mediumWeight], rng);
    let buttonWeight = getRandomFromArray([mediumWeight,heavyWeight], rng);

    let contentTextAlign = getFlip(rng)?'left':'center';

    return {
        fontFamily: fontFamily,
        caps: caps,
        textWeight: {
            title: titleTextWeight,
            card: titleTextWeight,
            navi: mediumWeight,
            header: headerWeight,
            footer: regularWeight,
            figureValue: regularWeight,
            figureValueUnit: mediumWeight,
            figureLabel: mediumWeight,
            button: buttonWeight,
        },
        figureTextAlign: contentTextAlign,
        placeholderTextAlign: contentTextAlign,
    };
}

export default makeType;
